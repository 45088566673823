import {
  AfterViewInit,
  Component,
  Inject,
  PLATFORM_ID,
  HostListener,
  OnInit, AfterViewChecked
} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { tap } from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { environment } from "../../environments/environment";

import { Gallery, GalleryInterface, ImageInterface } from "../drupal/entities";
import { GalleryResult } from "../drupal/resultTypes";
import { EntityMetaService } from "../drupal/entity-meta.service";
import { Drupal7Service } from "../drupal/drupal7.service";
import { Drupal7mappingService } from "../drupal/drupal7mapping.service";
import { AdPositionName } from "../ads/ad-position/ad-position-map";
import { AdServeServiceService } from "../ads/ad-serve/ad-serve-service.service";
import { SsspService } from "../seznam/sssp.service";

@Component({
  selector: 'app-gallery-detail',
  templateUrl: './gallery-detail.component.html',
  styleUrls: ['./gallery-detail.component.css']
})
export class GalleryDetailComponent implements OnInit {
  gallery: GalleryInterface | undefined;
  images: Array<ImageInterface> | undefined;
  titlePath: string | null = null;
  backRouterLink: string | undefined;

  protected readonly AdPositionName = AdPositionName;
  public readonly isBrowser: boolean;

  // Slide-based variables
  currentSlideIndex: number = 0; // 0-based: 1 is the ad
  totalSlides: number = 0;

  // Track the initial touch X position for swipe detection
  private touchStartX = 0;
  private SWIPE_THRESHOLD = 50; // Adjust for sensitivity

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entityMeta: EntityMetaService,
    private drupal7: Drupal7Service,
    private drupal7mapping: Drupal7mappingService,
    private adServe: AdServeServiceService,
    private sssp: SsspService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    // 1) Fetch gallery data
    this.route.paramMap
      .pipe(
        tap(params => {
          this.titlePath = params.get('titlepath');
          if (this.titlePath) {
            this.drupal7.getNode(this.titlePath)
              .pipe(
                tap((data: GalleryResult) => {
                  this.gallery = {
                    id: data.nid,
                    title: data.title,
                    path: '',
                    routerLink: '/fotogalerie/' + this.drupal7.generateNodePath(data.nid, data.title),
                    fullUrl: ''
                  };
                  this.gallery.fullUrl = environment.baseUrl + this.gallery.routerLink;

                  // Map images
                  this.images = this.drupal7mapping.mapImages(data.field_images.und);

                  // Set meta
                  this.entityMeta.setMeta(new Gallery(this.gallery));

                  // total slides = (number of images) + 1 for the ad
                  if (this.images) {
                    this.totalSlides = this.images.length + 1;
                  }

                  // Once we know totalSlides, read the query param for 'slide'
                  // to jump to a specific slide if present.
                  const slideParam = +this.route.snapshot.queryParamMap.get('slide')! || 0;
                  this.setSlideIndex(this.clampSlideIndex(slideParam));
                })
              )
              .subscribe();
          }
        })
      )
      .subscribe();

    // 2) Determine back link if fragment is given (article NID)
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const articleNid = parseInt(fragment.split('/').slice(-1).pop() as string, 10);
        if (articleNid > 0) {
          this.drupal7.getNodeById(articleNid).subscribe(data => {
            const article = this.drupal7mapping.mapArticle(data);
            this.backRouterLink = article.routerLink;
          });
        }
      }
    });
  }

  /**
   * Keyboard arrow navigation:
   * - ArrowLeft => prevSlide()
   * - ArrowRight => nextSlide()
   */
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.prevSlide();
    } else if (event.key === 'ArrowRight') {
      this.nextSlide();
    }
  }

  /**
   * Touchstart event handler for swipe detection.
   */
  onTouchStart(event: TouchEvent): void {
    if (event.touches && event.touches.length > 0) {
      this.touchStartX = event.touches[0].clientX;
    }
  }

  /**
   * Touchend event handler: compare startX with endX to detect swipe direction.
   */
  onTouchEnd(event: TouchEvent): void {
    if (!event.changedTouches || event.changedTouches.length === 0) {
      return;
    }
    const touchEndX = event.changedTouches[0].clientX;
    const dx = touchEndX - this.touchStartX;

    if (dx < -this.SWIPE_THRESHOLD) {
      // Swiped left => next slide
      this.nextSlide();
    } else if (dx > this.SWIPE_THRESHOLD) {
      // Swiped right => previous slide
      this.prevSlide();
    }
  }

  /**
   * Get the current image for the current slide index.
   * Slide 0 => images[0]
   * Slide 1 => Ad
   * Slide 2 => images[1], etc.
   */
  getCurrentImage(): ImageInterface | undefined {
    if (!this.images) return undefined;

    if (this.currentSlideIndex === 1) {
      // Slide 1 is the ad
      return undefined;
    }
    // Adjust for the ad slide if index > 1
    const realIndex = (this.currentSlideIndex > 1)
      ? this.currentSlideIndex - 1
      : this.currentSlideIndex;

    return this.images[realIndex];
  }

  nextSlide(): void {
    const newIndex = this.clampSlideIndex(this.currentSlideIndex + 1);
    this.setSlideIndex(newIndex);
  }

  prevSlide(): void {
    const newIndex = this.clampSlideIndex(this.currentSlideIndex - 1);
    this.setSlideIndex(newIndex);
  }

  private setSlideIndex(index: number): void {
    this.currentSlideIndex = index;
    this.updateUrlWithSlideParam(index);

    // If the user navigates to slide 1 (the ad slide) in the browser,
    // then re-serve the ad. We wrap in setTimeout() to ensure DOM is updated.
    if (index === 1 && this.isBrowser) {
      setTimeout(() => {
        this.sssp.setPageViewId();
        this.adServe.serveAds([AdPositionName.square]);
      }, 0);
    }
  }

  private clampSlideIndex(index: number): number {
    if (index < 0) return 0;
    if (index >= this.totalSlides) return this.totalSlides - 1;
    return index;
  }

  /**
   * Keep the #fragment (e.g. #back=xxx) and merge query params.
   */
  private updateUrlWithSlideParam(slideIndex: number): void {
    const currentFragment = this.route.snapshot.fragment;
    const fragmentValue: string | undefined = currentFragment ?? undefined;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { slide: slideIndex },
      queryParamsHandling: 'merge',
      fragment: fragmentValue
    });
  }
}
