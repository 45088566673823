import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  SecurityContext
} from '@angular/core';
import {Article, ArticleInterface} from "../../drupal/entities";
import {ArticleResult} from "../../drupal/resultTypes";
import {ActivatedRoute, Router} from "@angular/router";
import {Drupal7Service} from "../../drupal/drupal7.service";
import {EntityMetaService} from "../../drupal/entity-meta.service";
import {Drupal7mappingService} from "../../drupal/drupal7mapping.service";
import {tap} from 'rxjs';
import {AdServeServiceService} from "../../ads/ad-serve/ad-serve-service.service";
import {AdPositionName} from "../../ads/ad-position/ad-position-map";
import {DomSanitizer, SafeResourceUrl, SafeUrl} from "@angular/platform-browser";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {SsspService} from "../../seznam/sssp.service";
import {AdDisplayType} from "../../ads/ad-provider/ad-provider";
import {SklikComponent} from "../../ads/ad-provider/sklik/sklik.component";
import {DomParserFactoryService} from "../../services/dom-parser/dom-parser-factory.service";

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.css'],
})
export class ArticleDetailComponent implements AfterContentInit, AfterViewInit {
  article: ArticleInterface | undefined;
  protected readonly AdPositionName = AdPositionName;
  private usedTags: Array<any> = [];

  public fullUrl: SafeResourceUrl | null = null;

  private readonly isBrowser: boolean;
  displayType: AdDisplayType;

  constructor(
    private el: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private entityMeta: EntityMetaService,
    private drupal7: Drupal7Service,
    private drupal7mapping: Drupal7mappingService,
    private adServe: AdServeServiceService,
    private sanitized: DomSanitizer,
    private sssp: SsspService,
    @Inject(PLATFORM_ID) platformId: string,
    private readonly domParser: DomParserFactoryService,
    @Inject(DOCUMENT)
    private readonly document: Document,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.displayType = this.isBrowser ? (window.innerWidth > 767 ? AdDisplayType.desktop : AdDisplayType.mobile) : AdDisplayType.desktop;

    this.loadArticle();
  }

  ngAfterContentInit() {

  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      if (this.article) {
        this.entityMeta.setMeta(new Article(this.article));
      }

      this.sssp.setPageViewId();

      this.adServe.serveAds([
        AdPositionName.leaderboard,
        AdPositionName.articlePerex,
        AdPositionName.square,
        AdPositionName.stickySquare,
        AdPositionName.footerboard,
        AdPositionName.articleBottom,
        AdPositionName.smrMobilClanek2,
        AdPositionName.native,
      ]);
    }
  }

  private getPosition(haystack: string, needle: string, occurence: number = 0): number|undefined {
    return haystack.split(needle, occurence).join(needle).length;
  }

  private loadArticle() {
    this.route.paramMap.subscribe(params => {
      const titlePath = params.get('titlepath');
      if (titlePath) {
        // @ts-ignore
        this.drupal7.getArticle(titlePath).pipe(
          tap((data: ArticleResult) => {
            if (data.type === 'article') {
              this.article = this.drupal7mapping.mapArticle(data);
              this.fullUrl = this.article.fullUrl ? this.sanitized.bypassSecurityTrustResourceUrl(this.article.fullUrl) : null;
              this.entityMeta.setMeta(new Article(this.article));
            }
            else {
              this.router.navigate(['404']);
            }
          })
        ).subscribe((data: ArticleResult) => {
          if (this.article) {
            if (this.article.body) {
              if (this.isBrowser && this.displayType == AdDisplayType.mobile) {
                let nthParagraphStartPos = this.getPosition(this.article.body, '<p', 5);
                const adHtml: string = '<div class="mb-6 relative"><div class="ad-label">reklama</div><div style="min-height:300px;"><div id="sklik-zone-282740"></div><div id="ssp-zone-184821"></div></div></div>';
                const part1 = this.sanitized.sanitize(
                  SecurityContext.HTML,
                  this.article.body.slice(0, nthParagraphStartPos)
                );
                const part2 = this.sanitized.sanitize(
                  SecurityContext.HTML,
                  this.article.body.slice(nthParagraphStartPos)
                );
                this.article.body = [
                  part1,
                  adHtml,
                  part2,
                ].join('');
              }

              this.replaceArticlesInBody();
            }

            // @ts-ignore
            Object.values(data.field_temata.und).forEach((taxonomyTermValue: TaxonomyTermValue) => {
              this.drupal7.getTaxonomyTermById(taxonomyTermValue.tid).subscribe(taxonomyTermData => {
                if (this.usedTags.indexOf(taxonomyTermValue.tid) === -1) {
                  this.usedTags.push(taxonomyTermValue.tid);
                  // @ts-ignore
                  this.article.tags.push(this.drupal7mapping.mapTag(taxonomyTermData));
                }
              })
            });

            if (this.article) { // && this.isBrowser ... @TODO: add when backend is ready for such load
              this.drupal7.increaseNodeStatistics(this.article.id).subscribe(response => {
              });
            }
          }
        });
      }
    })
  }

  private replaceArticlesInBody() {
    if (this.article && this.article.body) {
      const doc = this.domParser.parse(this.article.body);

      // Find all drupal-entity tags
      const entities = doc.querySelectorAll('drupal-entity');
      entities.forEach((entity) => {
        const dataEntityId = entity.getAttribute('data-entity-id');
        if (dataEntityId) {
          const entityId = parseInt(dataEntityId);
          if (entityId) {
            const articleData = this.drupal7.getNodeById(entityId).subscribe((data) => {
              if (data.type == 'article') {
                let article = this.drupal7mapping.mapArticle(data);

                const div = this.document.createElement('div');
                div.innerHTML = `<div class="mb-6">
                  <div class="flex items-center space-x-6">
                    <div class="w-1/5">
                      <a href="${article.fullUrl}">
                        <img src="${article.image?.thumbnailUrl}" alt="${article.image?.alt}" class="w-full h-140 object-cover sm:h-140 sm:col-span-2 lg:col-span-full" alt="${article.image?.alt}" loading="lazy" />
                      </a>
                    </div>
                    <div class="w-4/5">
                      <h2 class="font-bold mb-2 mt-2">
                        <a href="${article.fullUrl}">
                          ${article.title}
                        </a>
                      </h2>
                    </div>
                  </div>
                </div>`;
                entity.appendChild(div);
              }
              if (this.article) {
                this.article.body = doc.bodyHtml;
              }
            });
          }
        }
      });
    }
  }

}
