<div class="bg-black relative w-full h-screen fixed top-0 left-0 right-0 bottom-0 overflow-hidden">
  <!-- Back Link (z-50 so it's on top) -->
  <a
    [routerLink]="backRouterLink"
    class="fixed top-6 left-6 text-white z-50"
  >
    <la-icon icon="long-arrow-alt-left"></la-icon> Zpět do článku
  </a>

  <!-- Main content -->
  <ng-container *ngIf="images && isBrowser; else loader">
    <div class="flex flex-col h-full justify-center items-center"
         (touchstart)="onTouchStart($event)"
         (touchend)="onTouchEnd($event)">

      <!-- Fullscreen container for current image or ad -->
      <div class="relative w-full h-full flex justify-center items-center">

        <!-- Slide 1 (index = 1) is the ad banner -->
        <ng-container *ngIf="currentSlideIndex === 1; else imageSlide">
          <app-ad-position [positionName]="AdPositionName.square" class="w-[300px] min-w-[300px]">
          </app-ad-position>
        </ng-container>

        <!-- All other slides show the current image -->
        <ng-template #imageSlide>
          <div class="flex flex-col items-center">
            <!-- The image itself -->
            <img
              [src]="getCurrentImage()?.url"
              [alt]="getCurrentImage()?.alt"
              class="max-w-full max-h-400 lg:max-w-[1200px] lg:max-h-[800px] object-contain"
            />

            <!-- Title & source (small white font) -->
            <div class="text-white text-sm mt-2 text-center" *ngIf="getCurrentImage()">
              <div>{{ getCurrentImage()?.title }}</div>
              <div>{{ getCurrentImage()?.copyright }}</div>
            </div>
          </div>
        </ng-template>

        <!-- Navigation (arrows) -->
        <button
          class="absolute left-4 top-1/2 -translate-y-1/2 bg-black text-white p-2 z-40 disabled:bg-black"
          (click)="prevSlide()"
          [disabled]="currentSlideIndex === 0"
        >
          ←
        </button>
        <button
          class="absolute right-4 top-1/2 -translate-y-1/2 bg-black text-white p-2 z-40 disabled:bg-black"
          (click)="nextSlide()"
          [disabled]="currentSlideIndex >= totalSlides - 1"
        >
          →
        </button>
      </div>

    </div>
  </ng-container>

  <!-- Loader (if images not yet loaded) -->
  <ng-template #loader>
    <div class="loader">
      <la-icon class="animate-spin" icon="spinner" size="2x"></la-icon>
    </div>
  </ng-template>
</div>
